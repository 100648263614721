.adjustLabel {
    margin-left: 5px;
    font-weight: 400;
    font-size: 15px;
    color: #014C6B;
}

.question-text {
    font-size: 1rem;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #014C6B;
};

.question-text-light {
  font-weight: 400 !important;
}

.text-subtitle {
  color: #014C6B;
  margin-top: -0.8rem;
  font-weight: 400;
}

// * {
//     margin: 0px;
//     padding: 0px;
//     box-sizing: border-box;
//   }
  :root{
    --fontfamily: 'GilroyAlt';
    --primary-color: #014C6B;
    --error-color: #D80606;
    --form-control-color: rebeccapurple;
  }
 
//  .form-fill-bg {
//    width: 100%;
//    min-height: 80vh;
//    background-color: #CFEBF6;
//    position: relative;
//    display: flex;
//    flex-direction: column;
//  }
 
//   .question-page {
//     position: relative;
//     width: 47%;
//     left: 26.5%;
//     top: 20%;
 
//     background-color: #FFFFFF;
//     border-radius: 12px;
 
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding: 50px 34px 30px 30px;
//     gap: 40px;
//     isolation: isolate;
//     margin-bottom: 5rem;
//   }
 
//  .pmed-page {
//    position: relative;
//    display: flex;
//    flex-direction: column;
//    min-height: 100vh;
//    border-radius: 0.75rem;
//    padding-right: 2rem;
//    padding-left: 1rem;
//    margin-bottom: 1rem;
//    gap: 0.25rem;
//  }
  
//  .quesions-row {
//    width: 100%;
//  }
 
//  .App-logo {
//    height: 40vmin;
//    pointer-events: none;
//  }
 
//  .submitAns {
//    padding: 10px;
//    background-color: #282c34;
//    color: #fff;
//    border-radius: 6px;
//    margin-top: 10px;
//    cursor: pointer;
//  }
 
//  input[type="button"]:disabled {
//    background-color: #d1d1d1;
//    border: #d1d1d1;
//  }
 
//  .submitAnsInput {
//    padding: 7px;
//    border-radius: 6px;
//  }
 
//  button:disabled {
//    background-color: #dddddd !important;
//  }
 
//  .sc-hKMtZM:last-child {
//    border: 1px solid red;
//    display: none;
//  }
 
//  .sc-jqUVSM {
//    cursor: pointer;
//  }

 #pmed-select {
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
  }

  .css-1s2u09g-control {
    align-items: center;
    background-color: hsl(0deg, 0%, 100%);
    border-color: hsl(0deg, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    width: 150px;
    margin-left: 1rem;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 50px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
  }
  
  
  .css-1pahdxg-control{
    //  width: 10rem;
    width: 10rem;
    padding: 0.25rem;
    box-shadow: none !important;
    margin-left: 0.5rem;
    &:hover{
      border-color: none !important;
    }
  .css-26l3qy-menu {
    top: 100%;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1);
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 0.5rem;
    position: absolute;
    width: 100%;
    width: 10rem;
    z-index: 1;
    box-sizing: border-box;
  
    .css-41jt47-MenuList .css-9gakcf-option {
    background-color: #FFFFFF !important;
    color: var(--primary-color) !important;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 10px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    } 
  }
  .css-1n7v3ny-option {
    background-color: #FFFFFF !important;
  }
  #react-select-2-listbox {
    color: var(--primary-color) !important;
  }
  }
}
  
 .spacing-1 {
   margin-bottom: 1rem;
 }
 .spacing-3 {
   margin-bottom: 3rem;
 }
 .spacing-4 {
   margin-bottom: 4rem;
 }
  
 .pmed-question-text {
   margin-top: 1rem;
 }
 
 .required-symbol{
   margin-left: -0.25rem;
   font-weight: 700;
   font-size: 1rem;
   color: var(--primary-color);
 }
  
 textarea, input[type='number'] {
   width: 100%;
   padding: 12px 20px;
   box-sizing: border-box;
   border: 2px solid #ccc;
   border-radius: 4px;
   font-size: 16px;
   font-family: sans-serif;
   resize: none;
   overflow: auto;
 }
 
 textarea:focus{
   border: 2px solid #4BB1B0 !important;
   outline: none !important;
 }
 
 .pmed-textarea textarea {
   width: 65%;
 }
 
 input[type='number'] {
   width: 10rem;
   &:focus{
     border: 2px solid #4BB1B0 !important;
     outline: none !important;
   }
 }
 
 
//  /* Styling Checkbox Start */
//  .checkbox-item {
//    display: inline-block;
//    // display: grid;
//    position: relative;
//    padding: 0 6px;
//    margin: 5px 20px 5px -5px;
//    align-items: center;
//  }
 
//  .checkbox-item input[type='checkbox'] {
//    display: none;
//  }
 
//  .checkbox-item label {
//    font-family: var(--fontfamily);
//    font-style: normal;
//    font-weight: 500;
//    font-size: 1rem;
//    line-height: 140%;
//    color: var(--primary-color);
//    margin-top: 2px;
//    margin-left: -2px;
//    text-indent: -15px;
//  }
 
//  .checkbox-item label:before {
//    content: " ";
//    display: inline-block;
//    position: relative;
//    top: 5px;
//    margin: 0px 20px -5px 0px;
//    width: 1.75rem;
//    height: 1.75rem;
//    border-radius: 6px;
//    border: 2px solid #014C6B;
//    background-color: transparent;
//  }
 
//  .checkbox-item input[type=checkbox]:checked + label:after {
//    border-radius: 6px;
//    width: 1.70rem;
//    height: 1.68rem;
//    position: absolute;
//    top: 5px;
//    left: 4px;
//    content: " ";
//    display: block;
//    background: var(--primary-color);
//    background-image: url("../components/PreviewForms/assets/TickBox.png");
//  }
//  /* Checkbox Item End */
 
//  /* Checkbox Item Error Start */
//  .checkbox-item-error {
//    display: inline-block;
//    position: relative;
//    padding: 0 6px;
//    margin: 5px 20px 5px -5px;
//    align-items: center;
//  }
 
//  .checkbox-item-error input[type='checkbox'] {
//    display: none;
//  }
 
//  .checkbox-item-error label {
//    font-family: var(--fontfamily);
//    font-style: normal;
//    font-weight: 500;
//    font-size: 1rem;
//    line-height: 140%;
//    color: var(--error-color);
//    margin-top: 2px;
//  }
 
//  .checkbox-item-error label:before {
//    content: " ";
//    display: inline-block;
//    position: relative;
//    top: 5px;
//    margin: 0px 20px -5px 0px;
//    width: 1.75rem;
//    height: 1.75rem;
//    border-radius: 6px;
//    border: 2px solid #D80606;
//    background-color: transparent;
//  }
 
//  .checkbox-item-error input[type=checkbox]:checked + label:after {
//    border-radius: 6px;
//    width: 1.70rem;
//    height: 1.68rem;
//    position: absolute;
//    top: 5px;
//    left: 4px;
//    content: " ";
//    display: block;
//    background: #D80606;
//    background-image: url("../components/PreviewForms/assets/TickBox.png");
//  }
 
//  /* Checkbox Item Error End */
 
//  /* Styling Radio Start */
//  .radio-item {
//    display: inline-block;
//    position: relative;
//    padding: 0px;
//    margin: 0px 20px 10px 0px;
//  }
 
//  .radio-item input[type='radio'] {
//    display: none;
//  }
 
//  .radio-item label {
//    font-family: var(--fontfamily);
//    font-style: normal;
//    font-weight: 500;
//    font-size: 1rem;
//    line-height: 140%;
//    color: var(--primary-color);
//  }
 
//  .radio-item label:before {
//    content: " ";
//    display: inline-block;
//    position: relative;
//    top: 5px;
//    margin: 0px 10px 0px 0px;
//    width: 1.5rem;
//    height: 1.5rem;
//    border-radius: 50px;
//    border: 2px solid #014C6B;
//    background-color: transparent;
//  }
 
//  .radio-item input[type=radio]:checked + label:after {
//    border-radius: 50px;
//    width: 1rem;
//    height: 1rem;
//    position: absolute;
//    top: 9px;
//    left: 4px;
//    content: " ";
//    display: block;
//    background: var(--primary-color);
//  }
//  /* Styling Radio End */
 
//  /* Radio Item Error Start */
//  .radio-item-error {
//    display: inline-block;
//    position: relative;
//    padding: 0px;
//    margin: 0px 20px 10px 0px;
//  }
 
//  .radio-item-error input[type='radio'] {
//    display: none;
//  }
 
//  .radio-item-error label {
//    font-family: var(--fontfamily);
//    font-style: normal;
//    font-weight: 500;
//    font-size: 1rem;
//    line-height: 140%;
//    color: var(--error-color);
//  }
 
//  .radio-item-error label:before {
//    content: " ";
//    display: inline-block;
//    position: relative;
//    top: 5px;
//    margin: 0px 10px 0px 0px;
//    width: 1.5rem;
//    height: 1.5rem;
//    border-radius: 50px;
//    border: 2px solid #D80606;
//    background-color: transparent;
//  }
 
//  .radio-item-error input[type=radio]:checked + label:after {
//    border-radius: 50px;
//    width: 1rem;
//    height: 1rem;
//    position: absolute;
//    top: 9px;
//    left: 4px;
//    content: " ";
//    display: block;
//    background: var(--error-color);
//  }
//  /* Radio Item Error End */
 
 
.form-control-checkbox {
  font-family: var(--fontfamily);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.form-control-checkbox + .form-control-checkbox {
  margin-top: 1rem;
}

.form-control-checkbox:focus-within {
  color: var(--primary-color);
}

.form-control-checkbox input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: sans-serif;
  color: currentColor;
  width: 1rem;
  height: 1rem;
  border: 2px solid currentColor;
  border-radius: 3px;
  transform: translateY(0.3rem);

  display: grid;
  place-content: center;
}

.form-control-checkbox input[type="checkbox"]::before {
  content: "";
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 2px;
  transform: scale(0);
  background: var(--primary-color);
  background-image: url("../assets/TickBox.png");
}

.form-control-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.form-control-checkbox input:focus {
  outline: none !important;
}
